/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo } from 'react';
import StyledImage from '@apps/components/Image';
import useMediaQuery from '@mui/material/useMediaQuery';
import BannerDesktop from '@stack/assets/images/banner-desktop.jpg';
import BannerMobile from '@stack/assets/images/banner-mobile.jpg';
import Link from 'next/link';

export const HomeBanner = () => {
  // ======================= Hooks
  const isMobile = useMediaQuery('(max-width:768px)');

  // ======================= VIEWS
  return (
    <Link target="_blank" href="https://xpeng.com.my/g6.php">
      <StyledImage
        src={isMobile ? BannerMobile : BannerDesktop}
        alt=""
        imageStyle={{
          objectFit: 'cover',
        }}
      />
    </Link>
  );
};

// ======================= EXPORT
export default memo(HomeBanner);

import ModelCarousel from '@apps/components/ModelCarousel';
import { IPopularModel } from '@apps/types';
import { TitleDivider } from '@stack/ui/components';
import useTranslation from 'next-translate/useTranslation';

export type ModelSectionProps = {
  models: IPopularModel[];
  webView?: boolean;
};

/**
 * ===========================
 * MAIN
 * ===========================
 */
export const ModelSection: React.FC<ModelSectionProps> = (props) => {
  const { models, webView = false } = props;

  // ======================= HOOKS
  const { t } = useTranslation('common');

  // ======================= VARIABLES
  const modelList = models?.map((model) => {
    return {
      href: webView
        ? `/webview/latest/${model.car_brand_slug}/${model.car_model_slug}`
        : `/latest/${model.car_brand_slug}/${model.car_model_slug}`,
      image: model.car_model_image,
      name: model.car_model_name,
      brand: model.car_brand_name,
    };
  });

  // ======================= VIEWS
  if (!models || models.length === 0) return null;
  return (
    <TitleDivider title={t('newsByModel')}>
      <ModelCarousel models={modelList} webView={webView} />
      {/* <ModelGroup models={modelList} /> */}
    </TitleDivider>
  );
};

/**
 * ===========================
 * EXPORTS
 * ===========================
 */
export default ModelSection;

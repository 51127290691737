import { Swiper } from '@stack/ui/components';
import styled from 'styled-components';

import ModelItem from '../ModelGroup/ModelItem';

import { ModelCarouselProps } from '.';

const ModelCarouselWrapper = styled.div`
  border: 1px solid #edeced;
  padding: 15px 20px;
  padding-top: 10px;
`;

/**
 * ===========================
 * MAIN
 * ===========================
 */
export const StandardModelCarousel: React.FC<ModelCarouselProps> = (props) => {
  const { models } = props;

  // ======================= VIEWS
  return (
    <ModelCarouselWrapper>
      <Swiper
        list={models}
        navigation={false}
        isSliderButtonCentered
        allowTouchMove
        renderSlide={(model, index) => {
          return (
            <ModelItem
              key={index}
              href={model.href}
              image={model.image}
              name={model.name}
              brand={model.brand}
            />
          );
        }}
        breakpoints={{
          0: {
            slidesPerView: 2,
          },
          330: {
            slidesPerView: 2.5,
          },
          // 375: {
          //   slidesPerView: 2,
          // },
          460: {
            slidesPerView: 3,
          },
          794: {
            slidesPerView: 5,
          },
          // 580: {
          //   slidesPerView: 4,
          // },
          // 768: {
          //   slidesPerView: 4,
          // },
          // 800: {
          //   slidesPerView: 4,
          // },
        }}
        spaceBetween={10}
        slidesPerView={{
          extraSmallMobile: 2,
          mobile: 4,
          ipad: 6,
          desktop: 7,
          extraBigScreen: 7,
        }}
        height={'235px'}
      />
    </ModelCarouselWrapper>
  );
};

/**
 * ===========================
 * EXPORTS
 * ===========================
 */
export default StandardModelCarousel;

import Link from 'next/link';

import StyledImage from '../Image';

import ImageWrap, { ImageHoverWrapper } from './styles';

export type ModelItemProps = {
  image: string;
  name: string;
  brand: string;
  href: string;
};

/**
 * ===========================
 * MAIN
 * ===========================
 */
export const ModelItem: React.FC<ModelItemProps> = (props) => {
  const { image, name, brand, href } = props;

  // ======================= VIEWS
  return (
    <div
      style={{
        display: 'inline-block',
        padding: '6px',
      }}
    >
      <Link
        href={href}
        style={{
          textDecoration: 'none',
          color: 'inherit',
          position: 'relative',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <ImageWrap>
            <ImageHoverWrapper>
              <StyledImage
                src={image}
                alt={name}
                width={345}
                height={195}
                imageStyle={{
                  height: '130px',
                  width: '130px',
                  objectFit: 'contain',
                  objectPosition: '-10px 70%',
                }}
              />
            </ImageHoverWrapper>
          </ImageWrap>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            <h4
              className="text-xl url-ani-2"
              style={{
                fontWeight: 700,
                margin: 0,
                color: '#1E242F',
              }}
            >
              {name}
            </h4>
            <p
              style={{
                fontSize: 12,
                fontWeight: 400,
                marginTop: -1,
              }}
            >
              {brand}
            </p>
          </div>
        </div>
      </Link>
    </div>
  );
};

/**
 * ===========================
 * EXPORTS
 * ===========================
 */
export default ModelItem;
